nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    background-color: white;
}

.nav-logo {
    color: black;
    font-size: 2rem;
    cursor: pointer;
    font-weight: bolder;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}

.nav-menu li {
    justify-content: space-evenly;
}

.nav-links {
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    justify-content: space-between;

}

.nav-links {
    padding-right: 10px;
}

.nav-links:hover {
    background-color: #01959a;
    color: white;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.nav-links-mobile {
    display: none;
}

button {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    border: none;
    font-weight: 600;
    transition: 0.2s ease-in-out;
    font-size: 1rem;

    cursor: pointer;
}

button:hover {
    background-color: black;
    color: white;
}

.hamburger {
    display: none;
}

@media screen and (max-width: 850px) {
    nav {
        z-index: 99;
    }

    .nav-menu {
        display: flex;
        background-color: white;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        position: absolute;
        align-items: stretch;
        top: 0;
        left: -100%;
        padding: 80px 0 30px 0;
        margin: 0;
        opacity: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;

    }

    .nav-menu.active {
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;

    }

    .nav-links {
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }


    .nav-links:hover {
        background-color: #01959a;
        transition: none;
    }


    .hamburger {
        display: block;

    }

    .hamburger{
        font-size: 1.2rem;
        color: black;
        cursor: pointer;
    }

    button {
        display: none;
    }


    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width: 80%;
        background: #01959a;
        text-decoration: none;
        color: white;
        font-size: 1.2rem;
        letter-spacing: 2px;
        font-weight: 700;
    }


}